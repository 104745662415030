<template>
  <v-container>
    <v-card-title>
      Edit your profile
      <v-spacer />
      <v-btn
        color="primary"
        icon
        @click="editState = !editState"
        data-testid="edit-button"
      >
        <v-icon>
          {{ editState ? 'mdi-close' : 'mdi-pencil' }}
        </v-icon>
      </v-btn>
    </v-card-title>

    <v-row justify="center" class="mt-2 mb-6">
      <v-avatar
        v-if="$store.state.user.userAttributes.picture"
        size="150"
        class="image-border"
      >
        <v-img :src="$store.state.user.userAttributes.picture">
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular
                indeterminate
                color="grey darken-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </v-avatar>
      <v-avatar v-else size="150" color="primary" class="white--text text-h3">
        {{ userInitial }}
      </v-avatar>
      <v-dialog v-model="dialog" width="400">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            small
            class="ml-n12"
            color="grey"
            dark
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-camera</v-icon>
          </v-btn>
        </template>
        <Dialog heading="Edit profile image" @close-dialog="dialog = false">
          <v-file-input
            class="mt-4"
            label="Upload image here"
            accept="image/*"
            show-size
            outlined
            counter
            prepend-icon="mdi-camera"
            v-model="image"
          ></v-file-input>
          <v-btn
            color="error"
            class="mx-1"
            v-if="$store.state.user.userAttributes.picture"
            @click="deleteImage"
          >
            Delete image
          </v-btn>
          <v-btn
            class="mx-1"
            :disabled="!image"
            color="primary"
            @click="saveImage"
          >
            Save
          </v-btn>
        </Dialog>
      </v-dialog>
    </v-row>

    <v-form class="mx-4" :disabled="!this.editState" v-model="isFormValid">
      <v-row>
        <v-col cols="6">
          <v-text-field
            v-model="name"
            data-testid="name"
            label="Name*"
            outlined
            clearable
            :rules="[validateNotEmpty]"
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="email"
            data-testid="email"
            label="Email"
            outlined
            disabled
          ></v-text-field>
        </v-col>
      </v-row>
      <v-divider />
      <v-card-subtitle>
        Occupation
      </v-card-subtitle>
      <v-row>
        <v-col cols="6">
          <v-text-field
            v-model="organization"
            data-testid="organization"
            label="Institution"
            outlined
            clearable
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="position"
            data-testid="position"
            label="Position"
            outlined
            clearable
          ></v-text-field>
        </v-col>
      </v-row>
      <v-divider />
      <v-card-subtitle>
        Location
      </v-card-subtitle>
      <v-row>
        <v-col cols="6">
          <v-text-field
            v-model="city"
            data-testid="city"
            label="City"
            outlined
            clearable
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-autocomplete
            :items="countries"
            item-text="name"
            label="Country"
            outlined
            clearable
            data-testid="country"
            v-model="country"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-card-actions class="justify-center">
        <v-btn
          color="primary"
          @click="submit"
          :disabled="editState ? !isFormValid : true"
          data-testid="save-changes"
        >
          Save Changes
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-container>
</template>

<script>
const {
  CognitoIdentityClient,
  GetIdCommand,
  GetCredentialsForIdentityCommand
} = require('@aws-sdk/client-cognito-identity')
const {
  fromCognitoIdentityPool
} = require('@aws-sdk/credential-provider-cognito-identity')
const {
  S3Client,
  PutObjectCommand,
  ListObjectsCommand
} = require('@aws-sdk/client-s3')

import UserPool from '@/plugins/UserPool'
import { validateNotEmpty } from '@/utilities/validationRules'
import { CognitoUserAttribute } from 'amazon-cognito-identity-js'
import countries from '@/assets/data/countries.json'

import Dialog from '@/components/Dialog'

export default {
  name: 'AccountInfo',
  components: { Dialog },
  data() {
    return {
      editState: false,
      dialog: false,
      // user details
      user: null,
      // user attributes
      name: '',
      email: '',
      organization: '',
      position: '',
      country: '',
      city: '',
      isFormValid: false,
      image: null,
      countries
    }
  },
  computed: {
    userInitial() {
      let name = this.$store.state.user.userAttributes.name
      if (name === undefined) {
        return ' '
      }
      let names = name.split(' ')
      let initial = ''
      for (let i = 0; i < names.length; i++) {
        initial += names[i][0]
      }
      return initial.toUpperCase()
    }
  },
  created() {
    this.getUserAttributes()
  },
  methods: {
    validateNotEmpty,
    async saveImage() {
      this.dialog = false

      const REGION = 'us-west-2'
      const albumName = 'profile'
      const albumBucketName =
        process.env.VUE_APP_ENV === 'production'
          ? 'portalimage-prod'
          : 'portalimage-stag'
      
      // var response = await this.authenticate(REGION) // If there are no issues, DELETE this
      let cognitoProviderName = "cognito-idp." + REGION + ".amazonaws.com/" + process.env.VUE_APP_COGNITO_POOL_ID

      const s3 = new S3Client({
        region: REGION,
        credentials: fromCognitoIdentityPool({
          client: new CognitoIdentityClient({ region: REGION }),
          identityPoolId: process.env.VUE_APP_COGNITO_IDENTITY_POOL_ID_IMAGE,
          logins: {
            [`${cognitoProviderName}`]: this.$store.state.user.session.idToken.jwtToken
          }
        })
      })
      const albumPhotosKey = encodeURIComponent(albumName) + '/'
      const file = this.image
      const fileExtension = file.name.split('.')[1]
      const fileName =
        this.$store.state.user.userAttributes['cognito:username'] +
        '.' +
        fileExtension
      const photoKey = albumPhotosKey + fileName
      const uploadParams = {
        Bucket: albumBucketName,
        Key: photoKey,
        Body: file
      }

      try {
        const data = await s3.send(new PutObjectCommand(uploadParams))
        let attributeUrl = new CognitoUserAttribute({
          Name: 'picture',
          Value:
            'https://' +
            albumBucketName +
            '.s3.us-west-2.amazonaws.com/profile/' +
            fileName
        })
        let attributeList = [attributeUrl]
        this.editUserAttributes(attributeList)
        this.$store.dispatch('getSession')
      } catch (err) {
        console.log(err) // DEBUG
        const notification = {
          type: 'error',
          message: 'There was an error uploading your photo'
        }
        this.$store.dispatch('addNotification', notification)
      }
    },
    deleteImage() {
      this.dialog = false
      let attributeUrl = new CognitoUserAttribute({
        Name: 'picture',
        Value: ''
      })
      let attributeList = [attributeUrl]
      this.editUserAttributes(attributeList)
      this.$store.dispatch('getSession')
    },
    submit() {
      let attributeName = new CognitoUserAttribute({
        Name: 'name',
        Value: this.name
      })
      let attributeEmail = new CognitoUserAttribute({
        Name: 'email',
        Value: this.email
      })
      let attributeCountry = new CognitoUserAttribute({
        Name: 'custom:country',
        Value: this.country
      })
      let attributeCity = new CognitoUserAttribute({
        Name: 'custom:city',
        Value: this.city
      })
      let attributeOrganization = new CognitoUserAttribute({
        Name: 'custom:organization',
        Value: this.organization
      })
      let attributePosition = new CognitoUserAttribute({
        Name: 'custom:position',
        Value: this.position
      })
      let attributeList = [
        attributeName,
        attributeEmail,
        attributeCountry,
        attributeCity,
        attributeOrganization,
        attributePosition
      ]
      this.editUserAttributes(attributeList)
      this.$store.dispatch('getSession')
      this.editState = false
    },
    editUserAttributes(attributeList) {
      const user = UserPool.getCurrentUser()
      user.getSession((err, session) => {
        if (err) {
          const notification = {
            type: 'error',
            message: err.message
          }
          this.$store.dispatch('addNotification', notification)
        } else {
          user.updateAttributes(attributeList, (err, result) => {
            if (err) {
              const notification = {
                type: 'error',
                message: err.message
              }
              this.$store.dispatch('addNotification', notification)
            } else {
              const notification = {
                type: 'success',
                message: 'Your profile is successfully edited'
              }
              this.$store.dispatch('addNotification', notification)
              location.reload() // REPLACE with better method
            }
          })
        }
      })
    },
    getUserAttributes() {
      this.user = JSON.parse(
        JSON.stringify(this.$store.state.user.userAttributes)
      )

      this.name = this.user.name
      this.email = this.user.email
      this.organization = this.user['custom:organization']
      this.position = this.user['custom:position']
      this.country = this.user['custom:country']
      this.city = this.user['custom:city']
    },
    // If there are no issues, DELETE this
    // async authenticate(region) {
    //   const client = new CognitoIdentityClient({
    //     region: region
    //   });
    //   var input = {
    //     IdentityPoolId: process.env.VUE_APP_COGNITO_IDENTITY_POOL_ID_IMAGE
    //   }
    //   var command = new GetIdCommand(input)
    //   var response = await client.send(command)
    //   let cognitoProviderName = "cognito-idp." + region + ".amazonaws.com/" + process.env.VUE_APP_COGNITO_POOL_ID
    //   input = {
    //     IdentityId: response.IdentityId,
    //     Logins: {
    //       [`${cognitoProviderName}`]: this.$store.state.user.session.idToken.jwtToken
    //     }
    //   }
    //   command = new GetCredentialsForIdentityCommand(input)
    //   response = await client.send(command)
    //   return response
    // }
  }
}
</script>

<style scoped>
.image-border {
  border: 4px solid #0c5c7e;
}
</style>
