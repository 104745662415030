<template>
  <EditUser />
</template>

<script>
import EditUser from '@/components/profile/EditUser';

export default {
  name: 'EditProfile',
  components: { EditUser },
};
</script>
