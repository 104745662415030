<template>
  <v-container>
    <v-form class="mx-4" v-model="isFormValid" ref="form">
      <v-card-title>
        Change Password
        <v-spacer />
      </v-card-title>
      <v-text-field
        class="mx-10"
        v-model="currentPassword"
        data-testid="current-password"
        label="Current Password"
        :rules="[validateNotEmpty]"
        outlined
        clearable
        :append-icon="currentPasswordVisible ? 'mdi-eye-off' : 'mdi-eye'"
        @click:append="() => (currentPasswordVisible = !currentPasswordVisible)"
        :type="currentPasswordVisible ? 'text' : 'password'"
      ></v-text-field>

      <v-text-field
        class="mx-10"
        v-model="newPassword"
        data-testid="new-password"
        label="New Password"
        outlined
        clearable
        :append-icon="newPasswordVisible ? 'mdi-eye-off' : 'mdi-eye'"
        @click:append="() => (newPasswordVisible = !newPasswordVisible)"
        :type="newPasswordVisible ? 'text' : 'password'"
        :rules="[validatePassword]"
      ></v-text-field>

      <v-text-field
        class="mx-10"
        v-model="confirmPassword"
        data-testid="confirm-password"
        label="Confirm Password"
        outlined
        clearable
        :append-icon="newPasswordVisible ? 'mdi-eye-off' : 'mdi-eye'"
        @click:append="() => (newPasswordVisible = !newPasswordVisible)"
        :type="newPasswordVisible ? 'text' : 'password'"
        :rules="[validateSamePassword(this.newPassword, this.confirmPassword)]"
      ></v-text-field>

      <v-card-actions class="justify-center">
        <v-btn
          color="primary"
          @click="changePassword"
          :disabled="!isFormValid"
          data-testid="change-password"
        >
          Change Password
        </v-btn>
      </v-card-actions>

      <v-card-title>
        Delete Account
        <v-spacer />
      </v-card-title>

      <v-card-actions class="justify-center">
        <v-dialog v-model="dialog" width="400">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="red"
              dark
              v-bind="attrs"
              v-on="on"
              data-testid="delete-account"
            >
              Delete account
            </v-btn>
          </template>
          <Dialog heading="Delete account" @close-dialog="dialog = false">
            <v-card-text>
              Are you sure you want to delete this account? This action is
              irreversible. Please write <b>Confirm Delete</b> to enable
              deletion of your account.
            </v-card-text>
            <v-form v-model="confirmDelete">
              <v-text-field
                placeholder="Confirm Delete"
                :rules="[validateDelete]"
                solo
                outlined
                dense
              >
              </v-text-field>
            </v-form>
            <v-btn
              color="red"
              dark
              @click="onDelete"
              :disabled="!confirmDelete"
              data-testid="confirm-delete"
            >
              Delete
            </v-btn>
          </Dialog>
        </v-dialog>
      </v-card-actions>
    </v-form>
  </v-container>
</template>

<script>
import {
  validateSamePassword,
  validatePassword,
  validateNotEmpty,
  validateDelete,
} from "@/utilities/validationRules";
import UserPool from "@/plugins/UserPool";
import Dialog from "@/components/Dialog";

export default {
  name: "AccountPreferences",
  components: { Dialog },
  data: () => {
    return {
      user: null,
      isFormValid: false,
      confirmDelete: false,
      dialog: false,
      // password info
      currentPasswordVisible: false,
      newPasswordVisible: false,
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    };
  },
  methods: {
    validateSamePassword,
    validatePassword,
    validateNotEmpty,
    validateDelete,
    changePassword() {
      const user = UserPool.getCurrentUser();
      if (user) {
        user.getSession((err, session) => {
          if (err) {
            const notification = {
              type: "error",
              message: err.message,
            };
            this.$store.dispatch("addNotification", notification);
          } else {
            user.changePassword(
              this.currentPassword,
              this.newPassword,
              (err, result) => {
                if (err) {
                  const notification = {
                    type: "error",
                    message: err.message,
                  };
                  this.$store.dispatch("addNotification", notification);
                } else {
                  const notification = {
                    type: "success",
                    message: "Password is successfully changed",
                  };
                  this.$store.dispatch("addNotification", notification);
                }
              }
            );
            this.isFormValid = false;
            this.$refs.form.reset();
          }
        });
      }
    },
    onDelete() {
      const user = UserPool.getCurrentUser();
      if (user) {
        user.getSession((err, session) => {
          if (err) {
            console.log(err);
          } else {
            user.deleteUser(function (err, result) {
              if (err) {
                const notification = {
                  type: "error",
                  message: err.message,
                };
                this.$store.dispatch("addNotification", notification);
              } else {
                console.log("delete success: ", result); // DEBUG
                location.reload();
              }
            });
          }
        });
      }
    },
  },
};
</script>
