<template>
  <v-container>
    <v-row align="center">
      <v-col>
        <v-card class="ma-auto" max-width="875">
          <v-tabs
            v-model="tab"
            background-color="primary"
            centered
            icons-and-text
            grow
            dark
          >
            <v-tab href="#details" data-testid="details-tab">
              Details
              <v-icon>mdi-account-details</v-icon>
            </v-tab>
            <v-tab href="#preferences" data-testid="preferences-tab">
              Preferences
              <v-icon>mdi-lock-reset</v-icon>
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item :key="1" value="details">
              <AccountInfo />
            </v-tab-item>
            <v-tab-item :key="2" value="preferences">
              <AccountPreferences />
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AccountInfo from './AccountInfo';
import AccountPreferences from './AccountPreferences';
export default {
  components: {
    AccountInfo,
    AccountPreferences,
  },
  data: () => {
    return {
      tab: 'projects',
    };
  },
};
</script>
